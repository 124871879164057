<template>
  <div class="notifications">
    <lenon-list-page
        :table-loading="tableLoading"
        title="Reports"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"
        search-placeholder="Type to search"
        :columns="columns"
        :rows="dailyReports"
        enable-multi-select
        show-profile-photo
        :per-page="100"
        @onRowSelect="handleSelection"
    >
      <template slot="table-header">
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="col-12"
        >
          <b-collapse
              id="reports"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                    v-model="reportType"
                    :options="reportTypes"
                    value-name="id"
                    label-name="title"
                    placeholder="Select Type"
                    class="mr-1"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                    v-model="selectedClassId"
                    :options="classes"
                    value-name="id"
                    label-name="name"
                    placeholder="Select Class"
                    class="mr-1"
                />
              </div>
              <div
                  v-if="reportType==='daily'"
                  class="col-md-6"
              >
                <lenon-date-picker
                    v-model="selectedDate"
                    label="Select Report Date"
                    :show-label="false"
                    class="mr-1"
                />
              </div>
              <div class="col-md-6 mb-1" v-if="reportType==='terminal'">
                <lenon-button
                    icon="PrinterIcon"
                    variant="outline-primary"
                    label="Generate"
                    tool-tip-text="Generate report cards"
                    @onClick="showReportCardModal()"
                />
              </div>
              <div class="col-md-6 mb-1">
                <lenon-button
                    icon="MessageCircleIcon"
                    variant="outline-primary"
                    label="Send"
                    :tool-tip-text="reportType==='terminal'?'Approve and send terminal reports':'Approve and send daily reports'"
                    @onClick="showSendDailyReportModal()"
                />
              </div>
              <div class="col-md-6 mb-1">
                <div class="row">
                  <div class="col-6">
                    <lenon-button
                        icon="SettingsIcon"
                        label="Form"
                        :tool-tip-text="reportType==='terminal'?'Create or update terminal report forms':'Create or update daily report forms'"
                        @onClick="showFormSetupModal()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
            style="margin-bottom: -18px !important;"
        >
          <lenon-select
              v-model="reportType"
              :options="reportTypes"
              value-name="id"
              label-name="title"
              placeholder="Select Type"
              class="mr-1"
          />
          <lenon-select
              v-model="selectedClassId"
              :options="classes"
              value-name="id"
              label-name="name"
              placeholder="Select Class"
              class="mr-1"
          />
          <lenon-date-picker
              v-if="reportType==='daily'"
              v-model="selectedDate"
              label="Select Report Date"
              :show-label="false"
              class="mr-1"
          />
          <lenon-button
              v-if="reportType==='terminal'"
              class="mr-1"
              icon="PrinterIcon"
              variant="outline-primary"
              label="Generate"
              tool-tip-text="Generate report cards"
              @onClick="showReportCardModal()"
          />
          <lenon-button
              icon="MessageCircleIcon"
              class="mr-1"
              variant="outline-primary"
              label="Send"
              :tool-tip-text="reportType==='terminal'?'Approve and send terminal reports':'Approve and send daily reports'"
              @onClick="showSendDailyReportModal()"
          />
          <!--          <lenon-button-->
          <!--            icon="SettingsIcon"-->
          <!--            label="T. Form"-->
          <!--            variant="outline-primary"-->
          <!--            tool-tip-text="Create or update terminal report forms"-->
          <!--            class="mr-1"-->
          <!--            @onClick="showTerminalFormSetupModal()"-->
          <!--          />-->
          <lenon-button
              icon="SettingsIcon"
              label="Form"
              :tool-tip-text="reportType==='terminal'?'Create or update terminal report forms':'Create or update daily report forms'"
              @onClick="showFormSetupModal()"
          />
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-center"
        >
          <lenon-button
              v-b-toggle.reports
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
      <template #row-details="{row}">
        <b-card>
          <b-row
              class="mb-2"
          >
            <b-col
                md="12"
                class="mb-1"
            >
              <strong>Report Details </strong>
              <div class="row">
                <div
                    class="col-12"
                    v-html="row.item.message"
                />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #actions="{row}">
        <lenon-button
            icon-only
            :variant="'flat-primary'"
            :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
            @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>
    <form-setup
        :modal-opened="formSetupOpened"
        :report-type="reportType"
        @modalClosed="closeModal('form')"
    />
    <lenon-modal
        :title="reportType==='terminal'?'Send Terminal Reports':'Send Daily Reports'"
        :show="showDailyReportModal"
        ok-text="Send Reports"
        size="sm"
        :ok-disabled="!sms && !push"
        :loading="sendingReports"
        @onClose="closeModal('report')"
        @onOk="sendReportToParents"
    >
      <div class="row">
        <div class="col-12">
          Please select channels to send reports.
        </div>
      </div>
      <b-form-checkbox
          v-model="sms"
          class="mt-1"
      >
        Sms Notification (Paid)
      </b-form-checkbox>
      <b-form-checkbox
          v-model="push"
          class="mt-1"
      >
        Push Notification (Free)
      </b-form-checkbox>
    </lenon-modal>
    <validation-observer
        ref="reportForm"
        v-slot="{invalid}"
    >
      <lenon-modal
          title="Generate Report Cards"
          :show="generateReportMode"
          :show-overlay="generating"
          size="md"
          @onClose="generateReportMode=false"
      >
        <div class="row mb-1">
          <div
              class="col-12"
          >
            <div class="row">
              <div class="col-12">
                <small><b>Please select vacation, reopening date and classes to generate report cards.</b></small>
              </div>
              <div class="col">
                <lenon-date-picker
                    v-model="vacation_date"
                    label="Vacation Date"
                />
              </div>
              <div class="col">
                <lenon-date-picker
                    v-model="reopening_date"
                    label="Reopening Date"
                />
              </div>
            </div>
          </div>
        </div>
        <template slot="modal-actions">
          <div class="row float-right">
            <lenon-button
                variant="flat-danger"
                icon="XIcon"
                class="mr-1"
                label="Cancel"
                @onClick="generateReportMode=false"
            />
            <lenon-button
                label="Generate"
                :disabled="invalid"
                :loading="generating"
                loading-text="Loading..."
                @onClick="generateReport()"
            />
          </div>
        </template>
      </lenon-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BBadge, BCol, BCard, BFormCheckbox, VBToggle, BCollapse,
} from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import { DAILY_REPORTS_Q, REPORT_FORMS_Q } from '@/graphql/queries'
import { SEND_DAILY_REPORT_M } from '@/graphql/mutations'
import NotificationSetup from '@/views/admin/notifications/EventSetup.vue'
import AlertSetup from '@/views/admin/notifications/AlertSetup.vue'
import moment from 'moment'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonDatePicker from '@/lenon/components/LenonDatePicker'
import LenonSelect from '@/lenon/components/LenonSelect'
import FormSetup from '@/views/admin/reports/FormSetup'
import logData from '@/libs/log'
import LenonModal from '@/lenon/components/LenonModal'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'Reports',
  components: {
    LenonModal,
    FormSetup,
    LenonSelect,
    LenonDatePicker,
    LenonDropdownItem,
    LenonDropdown,
    NotificationSetup,
    LenonButton,
    LenonListPage,
    BBadge,
    BRow,
    BCol,
    BCard,
    AlertSetup,
    BFormCheckbox,
    BCollapse,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      formSetupOpened: false,
      terminalFormSetupOpened: false,
      currentPage: null,
      perPage: 5,
      searchTerm: null,
      initializing: false,
      generateReportMode: false,
      generating: false,
      vacation_date: null,
      reopening_date: null,
      sort: {
        column: 'created_at',
        order: 'desc',
      },
      tableLoading: false,
      alertModalOpened: false,
      showDailyReportModal: false,
      reducedClasses: {},
      selectedClassId: null,
      selectedDate: null,
      selectedStudents: [],
      sms: false,
      push: false,
      sendingReports: false,
      reportType: null,
      reportTypes: [{
        id: 'daily',
        title: 'Daily Report'
      }, {
        id: 'terminal',
        title: 'Terminal Report'
      }],
    }
  },
  computed: {
    dailyReports() {
      return this.$store.getters['dailyReports/dailyReports']
    },
    classes() {
      return this.allClasses.filter(c => this.schoolInfo.creche_classes.includes(c.id))
    },
    allClasses() {
      return this.$store.getters['termsClasses/classes']
    },
    schoolInfo() {
      return this.$store.getters['auth/school']
    },
    reportSettings() {
      return this.$store.getters['dailyReports/settings']
    },
    columns() {
      const show = this.$store.getters['app/isLargeScreen']
      return [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          label: 'Student',
          key: 'student_name',
          sortable: show,
        },
        {
          label: 'Teacher',
          key: 'teacher',
          sortable: show,
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ]
    },
  },
  watch: {
    reportType(type) {
      if (type) {
        this.loadDailyReports()
        this.$store.commit('dailyReports/setSettings', { reportType: this.reportType })
      }
    },
    selectedClassId(id) {
      if (id) {
        this.loadDailyReports()
      } else {
        this.$store.commit('dailyReports/setDailyReports', [])
      }
      this.$store.commit('dailyReports/setSettings', { selectedClassId: this.selectedClassId })
    },
    selectedDate(val) {
      if (val) {
        this.loadDailyReports()
        this.$store.commit('dailyReports/setSettings', { selectedDate: this.selectedDate })
      }
    },
  },
  mounted() {
    this.getReportForms()
    this.selectedClassId = this.reportSettings.selectedClassId
    this.selectedDate = this.reportSettings.selectedDate
    this.reportType = this.reportSettings.reportType || 'daily'
  },
  methods: {
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    generateReport() {
      this.generating = true
      this.$http.post('documents/generate-terminal-reports-for-creche', {
        class_id: this.selectedClassId,
        vacation_date: this.vacation_date,
        reopening_date: this.reopening_date
      }).then((res)=>{
        this.downloadFile(res.data.path, res.data.name)
      }).catch((res)=>{
        logData(res)
        this.showError("Failed to generate report cards")
      }).finally(()=>{
      this.generating = false;
      })
    },
    showReportCardModal() {
      if (!this.dailyReports.length) {
        return this.showInfo('Please select a class and make sure there are results for the current.')
      }
      this.generateReportMode = true
    },
    sendReportToParents() {
      this.sendingReports = true
      this.$apollo.mutate({
        mutation: SEND_DAILY_REPORT_M,
        variables: {
          input: {
            student_ids: this.selectedStudents.map(st => st.id),
            class_id: this.selectedClassId,
            date: this.selectedDate,
            type: this.reportType,
            sms: this.sms,
            push: this.push,
          },
        },
      })
          .then(res => {
            if (!res.errors && res.data?.sendDailyReports) {
              this.showSuccess('Reports are being sent')
            }
          })
          .catch(e => {
            this.showError('Failed to send reports')
            logData(e)
          })
          .finally(() => {
            this.sendingReports = false
          })
    },
    showSendDailyReportModal() {
      if (this.selectedStudents.length) {
        this.showDailyReportModal = true
      } else {
        this.showInfo('Please select a student report')
      }
    },
    handleSelection(selectedReports) {
      this.selectedStudents = selectedReports
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'form':
          this.formSetupOpened = false
          break
        case 'terminal-form':
          this.terminalFormSetupOpened = false
          break
        case 'report':
          this.showDailyReportModal = false
          break
      }
    },
    showFormSetupModal() {
      if (this.reportType) {
        this.formSetupOpened = true
      } else {
        this.showInfo('Please select report type')
      }
    },
    showTerminalFormSetupModal() {
      this.terminalFormSetupOpened = true
    },
    loadDailyReports() {
      if ((this.reportType === 'daily' && this.selectedClassId && this.selectedDate) || (this.reportType === 'terminal' && this.selectedClassId)) {
        this.tableLoading = true
        this.$apollo.query({
          query: DAILY_REPORTS_Q,
          variables: {
            class_id: this.selectedClassId,
            date: this.selectedDate,
            type: this.reportType,
          },
        })
            .then(res => {
              if (!res.errors) {
                this.$store.commit('dailyReports/setDailyReports', res.data.dailyReports)
              }
            })
            .catch(e => {
              logData(e)
            })
            .finally(() => {
              this.tableLoading = false
            })
      }
    },
    getReportForms() {
      this.$apollo.query({
        query: REPORT_FORMS_Q,
      })
          .then(res => {
            if (!res.errors) {
              this.$store.commit('dailyReports/setReportForms', res.data.reportForms)
            }
          })
          .catch(e => {
            logData(e)
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
