<template>
  <!--    ReportForm Form-->
  <validation-observer
    ref="formReportForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateReportFormMode?'Update':'Create'} ${reportType==='daily'?'Daily':'Terminal'} Report Form`"
      :show="reportFormModalOpened"
      :show-overlay="deletingReportForm"
      size="md"
      @onClose="closeReportFormModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedReportFormId"
            placeholder="All Forms"
            :options="reportForms"
            label-name="title"
            value-name="id"
            @input="populateReportFormForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteReportForm()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetReportFormForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="reportForm.title"
        name="form_name"
        placeholder="Enter form name"
        rules="required"
        label="Form Name"
      />
      <lenon-multi-select
        v-model="reportForm.class_ids"
        label="Select Classes"
        label-name="name"
        value-name="id"
        :options="filteredClasses"
        rules="required"
        @remove="handleRemoveClass"
      />
      <div class="mt-1" />
      <lenon-text-area
        v-model="reportForm.questions"
        label="JSON Code"
        placeholder="Copy and paste the JSON code from Survey JS"
        rules="required"
        name="json_code"
      />
      <div class="">
        <label class="text-danger">
          Below is a list of supported form elements, using an unsupported element will be ignored when sending daily reports.
        </label>
        <br>
        <b>Single Line Text, Long Text, Yes/No, Multi Select Dropdown, Dropdown, Checkboxes, Rating, Radio Group</b>
        <br>
        <b-link @click="openLink()">
          Click to Create Form on Survey JS
        </b-link>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeReportFormModal()"
          />
          <lenon-button
            :label="updateReportFormMode?'Update':'Create'"
            :disabled="invalid"
            :loading="reportFormLoading"
            loading-text="Loading..."
            @onClick="updateReportFormMode?updateReportForm():createReportForm()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BLink,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { CREATE_FORM_M, DELETE_FORM_M, UPDATE_FORM_M } from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'

export default {
  name: 'FormSetup',
  components: {
    LenonMultiSelect,
    LenonTextArea,
    ErrorDisplay,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BLink,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    reportType: {
      type: String,
      default: 'daily',
    },
  },
  data() {
    return {
      error: {},
      reportFormModalOpened: false,
      updateReportFormMode: false,
      reportFormLoading: false,
      deletingReportForm: false,
      selectedReportFormId: null,
      reportForm: {
        id: null,
        title: null,
        questions: null,
        class_ids: [],
      },
      removedClassIds: [],
    }
  },
  computed: {
    selectedReportForm() {
      return this.reportForms.find(ter => ter.id === this.selectedReportFormId)
    },
    reportForms() {
      return this.$store.getters['dailyReports/reportForms'].filter(rf => rf.type === this.reportType)
    },
    classes() {
      return this.allClasses.filter(c => this.schoolInfo.creche_classes.includes(c.id))
    },
    allClasses() {
      return this.$store.getters['termsClasses/classes']
    },
    schoolInfo() {
      return this.$store.getters['auth/school']
    },
    filteredClasses() {
      let selectedClasses = []
      // compile already selected classes
      this.reportForms.forEach(gs => {
        selectedClasses = [...selectedClasses, ...gs.class_ids.map(c => c)]
      })
      // return classes not selected yet
      return this.classes.filter(cls => {
        if (this.removedClassIds.includes(cls.id)) {
          return true
        }
        return !selectedClasses.includes(cls.id)
      })
    },
  },
  watch: {
    modalOpened(opened) {
      this.reportFormModalOpened = opened
    },
  },
  methods: {
    handleRemoveClass(id) {
      this.removedClassIds.push(id)
    },
    openLink() {
      window.open('https://surveyjs.io/create-free-survey', '_blank')
    },
    // reportForm
    resetReportFormForm() {
      this.removedClassIds = []
      this.reportForm = {
        id: null,
        title: null,
        questions: null,
        class_ids: [],
      }
      this.selectedReportFormId = null
      this.updateReportFormMode = false
      this.$refs.formReportForm.reset()
    },
    populateReportFormForm(reportForm) {
      if (this.selectedReportForm) {
        this.removedClassIds = []
        this.updateReportFormMode = true
        this.reportForm = { ...this.selectedReportForm, class_ids: this.selectedReportForm.class_ids.map(id => this.classes.find(cls => cls.id === id)) }
      } else {
        this.resetReportFormForm()
      }
    },
    closeReportFormModal() {
      this.reportFormModalOpened = false
      this.$emit('modalClosed')
    },
    updateReportForm() {
      this.error = {}
      if (!this.selectedReportFormId) {
        return
      }
      const reportForm = { ...this.reportForm, type: this.reportType, class_ids: this.reportForm.class_ids.map(rf => rf.id) }
      this.reportFormLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_FORM_M,
        variables: {
          input: reportForm,
        },
      })
        .then(res => {
          this.showSuccess('Updated reportForm successfully')
          this.reportFormLoading = false
          this.$store.commit('dailyReports/updateReportForm', res.data.updateReportForm)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update reportForm')
          this.reportFormLoading = false
        })
    },
    createReportForm() {
      this.error = {}
      this.reportFormLoading = true
      const reportForm = { ...this.reportForm, type: this.reportType, class_ids: this.reportForm.class_ids.map(rf => rf.id) }
      this.$apollo.mutate({
        mutation: CREATE_FORM_M,
        variables: { input: reportForm },
      })
        .then(res => {
          this.showSuccess('Created report form successfully')
          this.reportFormLoading = false
          this.$store.commit('dailyReports/addReportForm', res.data.createReportForm)
          this.resetReportFormForm()
        })
        .catch(err => {
          this.showError('Failed to create form')
          logData(err)
          this.error = err
          this.reportFormLoading = false
        })
    },
    deleteReportForm() {
      if (!this.selectedReportFormId) {
        this.showInfo('Please select a report form')
        return
      }
      this.deletingReportForm = true
      this.$apollo.mutate({
        mutation: DELETE_FORM_M,
        variables: { id: this.selectedReportFormId },
      })
        .then(() => {
          this.deletingReportForm = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('dailyReports/removeReportForm', this.selectedReportFormId)
          this.selectedReportFormId = null
          this.resetReportFormForm()
        })
        .catch(() => {
          this.deletingReportForm = false
        })
    },

  },
}
</script>
